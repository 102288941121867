<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담사 상세 급여정보</h2>
                <br>
                        <h3><span>상담사명 : {{staffName}}</span></h3>
                <br>
                        <h3><span>센터 : {{centerName}}</span></h3>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <select name="birth-yyyy" @change="getStaffPay()" v-model="startYY" class="ml-20" id="yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                    </div>
                    <table class="default_table mt-20 mb-00">
                        <tbody>
                            <tr>
                                <th>년도/월</th>
                                <th>상담회기</th>
                                <th>매출액<br>(미수금포함)</th>
                                <th>총 급여</th>
                                <th>소득세</th>
                                <th>지방소득세</th>
                                <th>실지급액</th>
                            </tr>
                            <tr v-for="(item, index) of payList" :key="index">
                                <td>{{item.permissionMonth}}</td>
                                <td>{{item.reportCnt}}</td>
                                <td>{{numberWithCommas(item.price)}}</td>
                                <td>{{numberWithCommas(item.staffPay)}}</td>
                                <td>{{numberWithCommas(item.incomeTax)}}</td>
                                <td>{{numberWithCommas(item.localIncomeTax)}}</td>
                                <td>{{numberWithCommas(item.realStaffPay)}}</td>
                            </tr>
                            <tr v-if="payList.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="default_table mt-20 mb-00">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>총 상담회기</th>
                                <th>총 매출액<br>(미수금포함)</th>
                                <th>총 급여</th>
                                <th>총 소득세</th>
                                <th>총 지방소득세</th>
                                <th>총 실지급액</th>
                            </tr>
                            <tr>
                                <td>합계</td>
                                <td>{{payTotal.reportCnt}}</td>
                                <td>{{numberWithCommas(payTotal.price)}}</td>
                                <td>{{numberWithCommas(payTotal.staffPay)}}</td>
                                <td>{{numberWithCommas(payTotal.incomeTax)}}</td>
                                <td>{{numberWithCommas(payTotal.localIncomeTax)}}</td>
                                <td>{{numberWithCommas(payTotal.realStaffPay)}}</td>
                            </tr>
                            <tr v-if="payTotal.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">목록</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        staffName: '',
        centerName: '',
        idx: -1,
        startYY: '',
        payList: [],
        payTotal: "",

    }),

    mounted() {

        let date = new Date()
        this.startYY = date.getFullYear()
        this.getStaffPay()
        this.getUserInfo()
    },

    methods: {

        // 상담사 데이터 api 호출
        getUserInfo() {

            this.axios.get(`/api/v1/user/${this.idx}`, {
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.staffName = res.data.userVO.name
                        this.centerName = res.data.userVO.centerName
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        getStaffPay() {

            let params = {
                date: this.startYY + ""
            }

            this.axios.get(`/api/v1/pay/staff/${this.idx}`, {
                params: params
            })
                .then(res => {
                    if (res.data.err === 0) {
                        
                        this.payList = res.data.payList
                        this.payTotal = res.data.payTotal
                        //this.staffName = res.data.payList[0].staffName
                        //this.centerName = res.data.payList[0].centerName

                    } else {
                        this.payList = []
                        this.payTotal = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

    }
}
</script>
